@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";

#app-wrapper > .row {
	min-height: 100vh;
}

#app-wrapper .panel {
	background: #000;
	color: #fefefe;
	padding-top: 15px;
}

#app-wrapper .panel .image-box {
	width: 60%;
	height: 60%;
	max-width: 200px;
	max-height: 200px;
	overflow: hidden;
}

#app-wrapper .panel .image-box img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 0 20%;
}

#app-wrapper .panel .linked-in {
	width: 24px;
	height: 24px;
}

#app-wrapper .content {
	padding-top: 15px;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
